.toaster-div {
  float: right;
  clear: both;
  margin-right: 0.8vw;
}

.toaster-div > div > div > span > svg {
  color: white;
  fill: white;
  stroke: white;
}

.success-toaster > div {
  background-color: #459a60;
  color: white;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 300px;
}

.error-toaster > div {
  background-color: #db3f37;
  color: white;
  text-align: left;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 300px;
}

.warning-toaster > div {
  background-color: #d9822b;
  color: white;
  text-align: left;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 300px;
}
